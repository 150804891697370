import Vue from "vue";
import VueRouter, { RouteConfig, Route } from "vue-router";
import AuthView from "@/views/auth/index.vue";
import NotFoundView from "@/views/404.vue";
import { NavigationGuardNext } from "vue-router/types/router";
import store from "@/store";
import dashboard from "@/views/dashboard/DashBoardView.vue";

Vue.use(VueRouter);

const requireAuth =
  () => async (to: Route, from: Route, next: NavigationGuardNext) => {
    const result = await store.dispatch("verify");
    if (!result) {
      document.location.href = "https://pmidata.co.kr/login";
    }
    return next();
  };

const routes: Array<RouteConfig> = [
  {
    path: "/auth",
    name: "auth",
    component: AuthView,
  },
  {
    path: "/",
    name: "dashboard",
    component: dashboard,
    beforeEnter: requireAuth(),
  },
  {
    path: "/chart",
    name: "chart",
    component: dashboard,
    beforeEnter: requireAuth(),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
