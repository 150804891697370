
import { Component, Vue } from 'vue-property-decorator'
@Component({})
export default class AuthView extends Vue {
  mounted () {
    this.verify()
  }

  async verify () {
    const result = await this.$store.dispatch('verify')
    if (result) {
      await this.$router.push({ path: '/' })
    } else {
      await this.$store.dispatch('redirect', '/login')
    }
  }
}
