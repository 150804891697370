import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import{ BootstrapVue, IconsPlugin } from "bootstrap-vue";
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import VueAxios from "vue-axios";
import {ins as axios} from "@/utils/axios";

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

import '@/assets/css/globals.css';
import '@/assets/css/styleguide.css'
import '@/assets/css/variable.css'

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueAxios, axios);
Vue.use(VueToast,{position: 'top-right'});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
