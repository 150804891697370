
  import Vue from "vue";
  import {Component} from "vue-property-decorator";

  @Component({})
  export default class DateFilter extends Vue {
    // private selectedDate = [];
    private startYear = this.year[0].value;
    private startMonth = this.month[0].value;
    private startQuarter = this.quarter[0].value;

    private endYear = this.year[this.year.length - 1].value;
    private endMonth = this.month.filter(({ exceptYear }) => {
      return exceptYear ? !exceptYear.includes(this.endYear) : true;
    }).reverse()[0].value;
    private endQuarter = this.quarter.filter(({ exceptYear })=>{
      return exceptYear ? !exceptYear.includes(this.endYear) : true;
    }).reverse()[0].value

    changeDate(startDt?: string, endDt?:string) {
      if(startDt && endDt){
        this.startYear = Number(startDt.substring(0,4));
        this.startMonth = Number(startDt.substring(4,6));
        this.startQuarter = Number(startDt.substring(4,5));
        this.endYear = Number(endDt.substring(0,4));
        this.endMonth = Number(endDt.substring(4,6));
        this.endQuarter = Number(endDt.substring(4,5));
      }
      this.$emit('update', {
        /*startDate: new Date( this.startYear, this.startQuarter*3-1, 1),
        endDate: new Date( this.endYear, this.endQuarter*3-1, 1),*/
        startDate: this.startYear+''+this.startQuarter,
        endDate: this.endYear+''+this.endQuarter,
      });
    }

    private filterMonth(year: number) {
      return this.month.filter(({ exceptYear }) => exceptYear ? !exceptYear.includes(year) : true )
    }

    private filterQuarter(year: number){
      return this.quarter.filter(({ exceptYear }) => exceptYear ? !exceptYear.includes(year) : true )
    }

    private get year () {
      return [
        { text: 2019 , value: 2019 },
        { text: 2020 , value: 2020 },
        { text: 2021 , value: 2021 },
        { text: 2022 , value: 2022 },
      ]
    }

    private get month () {
      return [
        {text: 1, value: 1 },
        {text: 2, value: 2 },
        {text: 3, value: 3 },
        {text: 4, value: 4 },
        {text: 5, value: 5 },
        {text: 6, value: 6 },
        {text: 7, value: 7, exceptYear: [ 2022 ] },
        {text: 8, value: 8, exceptYear: [ 2022 ] },
        {text: 9 , value: 9, exceptYear: [ 2022 ] },
        {text: 10, value: 10, exceptYear: [ 2022 ] },
        {text: 11 , value: 11, exceptYear: [ 2022 ] },
        {text: 12 , value: 12, exceptYear: [ 2022 ] },
      ];
    }

    private get quarter () {
      return [
        {text: 1, value: 1 },
        {text: 2, value: 2 },
        {text: 3, value: 3, exceptYear: [ 2022 ] },
        {text: 4, value: 4, exceptYear: [ 2022 ] },
      ];
    }

    private created () {
      this.changeDate();
    }

    clickSearch(){
      this.$emit('search');
    }



  }
