
  import Vue from "vue";
  import {Component, Prop} from "vue-property-decorator";

  @Component({})
  export default class AnalysisFilter extends Vue {
    @Prop() chartOn!: boolean;
    @Prop() downloadBtnShow!: boolean;
    private selected: string[] = [];
    private max = 2;
    private analysis = [
      { value: 'GEN', text: "구매자 성" },
      { value: 'AGE', text: "구매자 연령" },
      { value: 'ARA', text: "구매 지역" },
      { value: 'PRODUCT', text: "구매 상품 분류" },
      { value: 'DAY', text: "구매 요일" },
      { value: 'TIME', text: "구매 시간대" },
    ];

    private list_GEN = [
      { value: 1, text: "남" },
      { value: 2, text: "여" },
    ];
    private list_AGE = [
      { value: 2, text: "20대 이하" },
      { value: 3, text: "30대" },
      { value: 4, text: "40대" },
      { value: 5, text: "50대" },
      { value: 6, text: "60대" },
      { value: 7, text: "70대 이상" },
    ];
    private list_DAY = [
      { value: 1, text: "주중" },
      { value: 2, text: "주말" },
    ];
    private list_ARA = [
      { value: 1, text: "서울시 광진구 A동" },
      { value: 2, text: "서울시 구로구 A동" },
      { value: 3, text: "서울시 강북구 A동" },
      { value: 4, text: "서울시 중구 A동" },
      { value: 5, text: "서울시 송파구 A동" },
      { value: 6, text: "서울시 송파구 B동" },
      { value: 7, text: "서울시 송파구 C동" },
      { value: 8, text: "서울시 동대문구 A동" },
    ];
    private list_TIME = [
      { value: 1, text: "6시-12시" },
      { value: 2, text: "12시-18시" },
      { value: 3, text: "18시-24시" },
    ];
    private list_PRODUCT = [
      { value: 1, text: "과일및야채" },
      { value: 2, text: "기타" },
      { value: 3, text: "기타 식자재" },
      { value: 4, text: "기타 음료" },
      { value: 5, text: "델리/음식점" },
      { value: 6, text: "라면" },
      { value: 7, text: "문구/완구" },
      { value: 8, text: "미곡" },
      { value: 9, text: "반찬/장류/소스류" },
      { value: 10, text: "상온간편요리" },
      { value: 11, text: "생란" },
      { value: 12, text: "생수" },
      { value: 13, text: "수산물" },
      { value: 14, text: "과자류" },
      { value: 15, text: "요구르트" },
      { value: 16, text: "우유" },
      { value: 17, text: "육류" },
      { value: 18, text: "일반의류" },
      { value: 19, text: "재사용/종량제" },
      { value: 20, text: "제과류" },
      { value: 21, text: "주류" },
      { value: 22, text: "주방/청소용품" },
      { value: 23, text: "치즈" },
      { value: 24, text: "탄산음료" },
      { value: 25, text: "햄소시지 가공식품" },
      { value: 26, text: "화장품" },
    ];


    private firstOptions: number[] = [];
    private secondOptions: number[] = [];

    //design data

    private getSubList(key: string): {value:number, text:string}[]{
      const realKey = `list_${key}`;
      return this[realKey as keyof AnalysisFilter];
    }

    private isNotSelected(main:string): boolean{
      const idx = this.selected.indexOf(main);
      if(idx >=0){
        //선택 된 것 선택 > 선택 해제
        const newSelected = this.selected.filter((item)=> item !== main);
        this.selected = newSelected;
        if (idx === 0){
          //첫번째 선택된 것 > 퍼스트옵션 세컨옵션으로 대체
          this.firstOptions = this.secondOptions;
        }
        this.secondOptions = [];
        return false;
      }else{
        return true;
      }
    }

    mainBtnClick(main:string){
      const notSelect = this.isNotSelected(main);

      if(notSelect){
        this.selected.push(main);

        //3개 이상 선택 시 처음 선택 삭제
        if (this.selected.length > this.max) {
          this.selected = this.selected.slice(-this.max);
          this.firstOptions = this.secondOptions;
          this.secondOptions = [];
        }

        //처음 분석변수 선택 시 서브옵션 다 선택해주기
        if(this.selected.length === 1) {
          this.getSubList(this.selected[0]).forEach((item)=>{
            this.firstOptions.push(item.value);
          });
        }else{
          this.getSubList(this.selected[1]).forEach((item)=>{
            this.secondOptions.push(item.value);
          });
        }
      }

      this.change();
    }

    mainBtnPushClick(main:string){
      this.selected.push(main);
      this.change();
    }

    private getMainBtnActive(main:string){
      if( this.selected.indexOf(main) >= 0) return true;
      else return false;
    }

    subBtnClick(main:string, sub:number){
      if( this.selected.indexOf(main) === 1 ) {
      //두번재 변수의 옵션을 클릭함
        if( this.secondOptions.indexOf(sub) >= 0){
        //이미 선택한 옵션을 또 선택 > 삭제하기
          const filtered = this.secondOptions.filter((item)=> item !== sub);
          this.secondOptions = filtered;

          //서브 옵션 모두 미선택시 > 두번째 변수 선택 해제
          if(this.secondOptions.length === 0){
            const sliced = this.selected.slice(0,1);
            this.selected = sliced;
          }
        } else{
        //새로운 옵션 선택 > 걍 넣기
          this.secondOptions.push(sub);
        }
      } else {
        //첫번째 변수의 옵션을 클릭함
        if( this.firstOptions.indexOf(sub) >= 0){
        //이미 선택한 옵션을 또 선택 > 삭제하기
          const filtered = this.firstOptions.filter((item)=> item !== sub);
          this.firstOptions = filtered;

          //서브 옵션 모두 미선택 > 첫번째 변수 선택 해제, 두번재 옵션을 첫번째 옵션으로 옮기기
          if(this.firstOptions.length === 0){
            const sliced = this.selected.slice(1);
            this.selected = sliced;
            this.firstOptions = this.secondOptions;
            this.secondOptions = [];
          }
        } else{
        //새로운 옵션 선택 > 걍 넣기
          this.firstOptions.push(sub);
        }
      }
      this.subChange();
    }

    subBtnPushClick(main:string, sub:number){
      if( this.selected.indexOf(main) === 1 ) {
        //두번재 변수의 옵션을 클릭함 (옵션 다 들어있는 상태에서)
        const filtered = this.secondOptions.filter((item)=> item === sub);
        this.secondOptions = filtered;
        /*if( this.secondOptions.indexOf(sub) >= 0){
          //이미 선택한 옵션을 또 선택 > 삭제하기

          //서브 옵션 모두 미선택시 > 두번째 변수 선택 해제
          /!*if(this.secondOptions.length === 0){
            const sliced = this.selected.slice(1,1);
            this.selected = sliced;
            console.log(this.selected, 'ghghg');
          }*!/
        } else{
          //새로운 옵션 선택 > 걍 넣기
          this.secondOptions.push(sub);
        }*/
      } else {
        //첫번째 변수의 옵션을 클릭함
        const filtered = this.firstOptions.filter((item)=> item === sub);
        this.firstOptions = filtered;
        /*if( this.firstOptions.indexOf(sub) >= 0){
          //이미 선택한 옵션을 또 선택 > 삭제하기

          //서브 옵션 모두 미선택 > 첫번째 변수 선택 해제, 두번재 옵션을 첫번째 옵션으로 옮기기
          /!*if(this.firstOptions.length === 0){
            const sliced = this.selected.slice(0,1);
            this.selected = sliced;
            this.firstOptions = this.secondOptions;
            this.secondOptions = [];
          }*!/
        } else{
          //새로운 옵션 선택 > 걍 넣기
          this.firstOptions.push(sub);
        }*/
      }
      this.subChange();
    }

    private getSubBtnActive(main: string, sub:number){
      if ( this.selected.indexOf(main) === 1 && this.secondOptions.indexOf(sub)>= 0 ) return true;
      else if ( this.selected.indexOf(main) === 0 && this.firstOptions.indexOf(sub) >=0 ) return true;
      else return false;
    }

    // MARK: 분석 변수 change event
    private change() {
      const existsProduct = this.selected.includes('PRODUCT');
      if (existsProduct) {
        if (this.selected[0] === 'PRODUCT' && this.selected.length > 1) {
        //첫번째가 PRO(최근 선택이 PRO아님) > PO 지우고 두번째 옵션 첫번째 옵션으로 옮기고 두번째 옵션 비우기
          this.selected = this.selected.filter((item) => item !== 'PRODUCT');
          //옵션 선택 옮기고 옵션 리스트 비우기
          this.firstOptions = this.secondOptions;
          this.secondOptions = [];
        } else {
        //최근 선택이 PRO > 셀렉티드에 프로만 남기고 첫번째 옵션에 프로 옵션 다 넣고 두번째 옵션 비우기
          this.selected = [
            'PRODUCT'
          ];
          this.getSubList('PRODUCT').forEach((item)=>{
            this.firstOptions.push(item.value);
          });
          this.secondOptions = [];
        }
      }


      if(this.selected.length>1){
        const sendData = {
          selectors: this.selected,
          firstOptions: this.firstOptions,
          secondOptions: this.secondOptions,
        }
        this.$emit('update', sendData);
      }else{
        const sendData = {
          selectors: this.selected,
          firstOptions: this.firstOptions,
        }
        this.$emit('update', sendData);
      }
    }

    private subChange(){
      if(this.selected.length>1){
        const sendData = {
          selectors: this.selected,
          firstOptions: this.firstOptions,
          secondOptions: this.secondOptions,
        }
        this.$emit('update', sendData);
      }else{
        const sendData = {
          selectors: this.selected,
          firstOptions: this.firstOptions,
        }
        this.$emit('update', sendData);
      }
    }

    public get values() {
      return this.selected;/**/
    }

    private reset(){
      this.selected = [];
      this.firstOptions = [];
      this.secondOptions = [];
      this.$emit('reset');
    }

    private download(){
      this.$emit('download');
    }

    private downloadPDF(){
      this.$emit('downloadPDF');
    }

  }


