
import { Component, Vue, Emit } from 'vue-property-decorator';
import Nav, { IBasicMenu, IMainMenu } from '@/components/nav/index.vue';
import mixin from '@/utils/common';

@Component({
  components: { Nav }
})
export default class App extends Vue {
  /*  기본 options 사용법
   *  options 없이 전달시 기본 라우터
   *  anchor: true -> anchor 태그 사용
   *  tab: true -> 새창에 열림
   */

  // Logo 이미지
  private imgSrc = require('@/assets/img/logo.svg'); // require 로 넘겨주세요.
  private get token () {
    return this.$store.getters.token
  }
  $refs!: {
    nav: Nav;
  };
  private logo: string = require('@/assets/logo.svg')

  // 메인 타이틀
  private mainTitle: IBasicMenu = {
    title: '통합 데이터 허브',
    url: 'https://pmidata.co.kr/main',
    options: {
      anchor: true
    }
  }

  /* 메인 메뉴
    *  hover 메뉴는 detailMenu 에 담아서 넘기면 됩니다.
    *  detailMenu 도 options 사용 가능.
    *  detailMenu 는 mainMenu 에서만 가능.
    *  로그인 메뉴 로그인/로그아웃 변경 및 회원가입 메뉴 숨김은 메뉴 이름이 로그인, 회원가입일때만 적용.
    */
  private mainMenu: IMainMenu[] = [
    {
      title: '데이터 탐색',
      detailMenu: [
        {
          title: '뉴스/SNS 트렌드 데이터',
          url: `https://trend.pmidata.co.kr/mainpage.do?token=${this.token}`,
          options: {
            anchor: true
          }
        },
        {
          title: '모바일 행동 데이터',
          url: `https://mpd.pmidata.co.kr/api/users/dashboard/login/${this.token}`,
          options: {
            anchor: true
          }
        },
        {
          title: '공공 데이터',
          url: `https://public.pmidata.co.kr/api/auth/${this.token}`,
          options: {
            anchor: true
          }
        },
        {
          title: '결제 데이터',
          url: `https://transaction.pmidata.co.kr/api/auth/${this.token}`,
          options: {
            anchor: true
          }
        }
      ]
    },
    {
      title: '데이터 마켓',
      detailMenu: [
        {
          title: '데이터 셋',
          url: 'https://pmidata.co.kr/data-set',
          options: {
            anchor: true
          }
        },
        {
          title: '융합 데이터',
          url: 'https://pmidata.co.kr/fusion-data',
          options: {
            anchor: true
          }
        },
        {
          title: '맞춤형 데이터 서비스',
          url: 'https://pmidata.co.kr/custom-data-service',
          options: {
            anchor: true
          }
        }
      ]
    },
    {
      title: '데이터 작업소',
      detailMenu: [
        {
          title: '시각화 만들기',
          url: 'https://pmidata.co.kr/visualization',
          options: {
            anchor: true
          }
        },
        {
          title: '마이 데이터 랩',
          url: 'https://pmidata.co.kr/my-data-hub',
          options: {
            anchor: true
          }
        },
      ]
    },
    {
      title: '알림',
      detailMenu: [
        {
          title: '플랫폼 소개',
          url: 'https://pmidata.co.kr/about-plat-form',
          options: {
            anchor: true
          }
        },
        {
          title: '공지사항',
          url: 'https://pmidata.co.kr/notice',
          options: {
            anchor: true
          }
        },
        {
          title: '자주하는 질문',
          url: 'https://pmidata.co.kr/faq',
          options: {
            anchor: true
          }
        },
        {
          title: '문의사항',
          url: 'https://pmidata.co.kr/qna',
          options: {
            anchor: true
          }
        }
      ]
    }
  ];


  /* 서브 메뉴
   * 로그인 메뉴 로그인/로그아웃 변경 및 회원가입 메뉴 숨김은 메뉴 이름이 로그인, 회원가입일 때만 적용.
   */
  private subMenu: IBasicMenu[] = [
    {
      title: '로그아웃',
      url: 'https://pmidata.co.kr/logout',
      options: {
        anchor: true
      }
    },
    {
      title: '마이페이지',
      url: 'https://pmidata.co.kr/mypage',
      options: {
        anchor: true
      }
    },
    {
      title: '이용안내',
      url: 'https://pmidata.co.kr',
      options: {
        anchor: true
      }
    },
    {
      title: '사이트맵',
      url: '#',
      options: {
        anchor: true
      }
    }
  ];

  get isLogin () {
    return this.$store.getters.isLogin
  }

  get userId () {
    return this.$store.getters.userId
  }

  get userName () {
    return this.$store.getters.userName
  }

  /*logout(){
    return;
  }*/

  @Emit()
  logout () {
    this.$store.dispatch('clear')
  }

  // 메뉴 클릭 시 누른 메뉴의 title 값을 파라미터로 받음 (클릭 이벤트로 사용)
  @Emit()
  private clickMenu(titleStr: string) {
    console.log(titleStr, 'pp');
    const title = titleStr.replace(/(\s*)/g, "");
    /*if (title === '통합데이터허브') {
      /!*this.$store.commit('clickMyDataHubReset', true);*!/
      document.location.href="https://pmidata.co.kr/main";
    }else if (title === '모바일행동데이터') {
      document.location.href='https://mpd.pmirnc.io/dashboard';
    }else if( title === '공공데이터') {
      document.location.href='https://public.pmirnc.com/map';
    }else if( title === '결제데이터') {
      document.location.href='https://transaction.pmirnc.com/';
    }
    else if( title === '공지사항') {
      document.location.href='https://pmidata.co.kr/notice';
    }
    else if( title === '자주하는질문') {
      document.location.href='https://pmidata.co.kr/faq';
    }
    else if( title === '문의사항') {
      document.location.href='https://pmidata.co.kr/qna';
    }
    else if( title === '마이페이지') {
      document.location.href='https://pmidata.co.kr/mypage';
    }else if( title === '마이데이터허브') {
      document.location.href='https://pmidata.co.kr/my-data-hub';
    }else if ( title === '맞춤형데이터서비스'){
      document.location.href='https://pmidata.co.kr/custom-data-service';
    }else if( title === '데이터셋'){
      document.location.href='https://pmidata.co.kr/data-set';
    }else if (title === '크롤링데이터'){
      console.log(this.$store.state.isLogin, this.$store.state.token);
      document.location.href=`https://dzvisual.doculink.kr/mainpage.do?token=${this.$store.state.token}`;
    }else if (title === '융합데이터') {
      document.location.href=`https://pmidata.co.kr/fusion-data`;
    }else if (title === '시각화만들기') {
      document.location.href=`https://pmidata.co.kr/visualization`;
    }else if (title === '마이데이터랩') {
      document.location.href=`https://pmidata.co.kr/my-data-hub/my-home`;
    }else if (title === '뉴스/SNS트렌드데이터') {
      document.location.href=`http://dzvisual.doculink.kr/mainpage.do?token=${this.$store.state.token}`;
    }else if (title === '플랫폼소개') {
      document.location.href=`https://pmidata.co.kr/about-plat-form`;
    }else if (title === '회원가입') {
      document.location.href=`https://pmidata.co.kr/sign-up`;
    }else if (title === '로그인') {
      document.location.href=`https://pmidata.co.kr/login`;
    }else if (title === '사이트맵') {
      this.$refs.nav.hover(true);
    }*/
    if(title === '사이트맵') {
      this.$refs.nav.hover(true);
    }
  }
}
