
  import Vue from "vue";
  import { Component, Watch } from "vue-property-decorator";
  import DateFilter from "@/components/dashboard/DateFilter.vue";
  import AnalysisFilter from "@/components/dashboard/AnalysisFilter.vue";
  import { Chart, registerables  } from 'chart.js';
  import randomColor from 'randomcolor';
  import { addImage, firstPageAdd, isAddPageCheck_Before, pdfInit } from '@/utils/pdf';
  import { RESULT } from '@/types/result';
  import PDF_IMAGE_TYPE = RESULT.PDF_IMAGE_TYPE;
  import html2canvas from 'html2canvas';

  // MARK: Response Data
  interface ChartData {
    data: {
      [key: string]: Array<{ variable: string; crym: number; buy: number; count: number;}>
    };
    dates: Array<number>; // X축
    topLabel: Array<string>;
    topCountLabel: Array<string>;
  }

  interface IChartData {
    ARA?: number;
    GEN?: number;
    AGE?: number;
    DAY?: number;
    TIME?: number;
    BUY?: number;
    PRODUCT?: number;
    crym?: number | string;
    BUY_sum?: number;
    cnt_cno_sum?:number;
  }

  interface DownloadData {
    data: Array<{
      ARA?: number;
      GEN?: number;
      AGE?: number;
      DAY?: number;
      TIME?: number;
      BUY?: number;
      PRODUCT?: number;
      crym?: number | string;
      BUY_sum?: number;
      cnt_cno_sum?:number;
    }>;
  }

  @Component({
    components: { DateFilter,AnalysisFilter }
  })
  export default class DashBoardView extends Vue{
    private pending = false;
    private startDate: string | null = null;
    private endDate: string | null = null;
    private selectors: string[] = [];
    private firstOptions: number[] = [];
    private secondOptions: number[] = [];
    private chart?: Chart;
    private countChart?: Chart;
    private topScoreLabel = '';
    private topCountLabel = '';
    private colorArray:string[] = [];

    chartOn = false;
    downloadBtnShow = false;
    querys = this.$route.query;

    public $refs!: {
      chart: HTMLCanvasElement;
      countChart: HTMLCanvasElement;
      analysis: any;
      date: any;
    };

    constructor() {
      super();
    }
    private created() {
      // Chart.register(...registerables);
    }

    @Watch('querys')
    queryUpdate(){
      if(this.$route.query.startDate && this.$route.query.endDate && this.$route.query.selectors){
        this.selectorPush();
      }
    }


    private mounted() {
      if(this.$route.query.startDate && this.$route.query.endDate && this.$route.query.selectors){
        this.selectorPush();
      }
    }

    public beforeDestroy() {
      // this.chart?.destroy();
      // Chart.unregister(...registerables);
    }

    private get validateParams () {
      if (this.startDate === null) return false;
      if (this.endDate === null) return false;
      return this.selectors.length !== 0;
    }

    private dateToString(date:Date){
      return date.getFullYear()+''+((Number(date.getMonth().toString())+1)<10?'0'+(Number(date.getMonth().toString())+1):(Number(date.getMonth().toString())+1));
    }

    private selectorPush(){
      this.$refs.date.changeDate(this.$route.query.startDate, this.$route.query.endDate);
      this.$route.query.selectors.toString().split(',').forEach((item)=>{
        this.$refs.analysis.mainBtnPushClick(item);
      })
      if(this.$route.query.firstOptions !==''){
        this.$route.query.firstOptions.toString().split(',').forEach((item)=>{
          this.$refs.analysis.subBtnClick(this.selectors[0],Number(item));
        })
      }
      if(this.$route.query.secondOptions !==''){
        this.secondOptions = [];
        this.$route.query.secondOptions.toString().split(',').forEach((item)=>{
          this.$refs.analysis.subBtnClick(this.selectors[1],Number(item));
        })
      }
      this.search();
    }

    private async clickSearch(){
      if (!this.validateParams) {
        // TODO: notify같은 걸로 변경 바람
        this.$toast.error('분석 변수를 선택하세요.');
        return;
      }
      const startDate = this.startDate? this.startDate:'';
      const endDate = this.endDate? this.endDate:'';
      const selectors = this.selectors.join(',');
      const firstOptions = this.firstOptions.join(',');
      const secondOptions = this.secondOptions.join(',');
      const queryValue:{
        startDate: string,
        endDate: string,
        selectors: string,
        firstOptions: string,
        secondOptions?: string
      } = {
        startDate,
        endDate,
        selectors,
        firstOptions,
        secondOptions,
      }
      await this.$router.push({
        name: 'chart',
        query: queryValue
      });
      if(this.$route.query.startDate && this.$route.query.endDate && this.$route.query.selectors){
        this.search();
      }
    }

    private async search () {
      // MARK: 데이터 검증
      this.pending = true;

      try {
        // TODO: axios에 generic type 선언해야함
        const { data: response } = await this.axios.get<ChartData>('/chart/chart-list',{
          params: {
            startDate: this.startDate,
            endDate: this.endDate,
            selectors: this.selectors,
            firstOptions: this.firstOptions,
            secondOptions: this.secondOptions
          }
        });
        this.drawChart(response);
        this.drawCountChart(response);
      } catch (e) {
        // TODO: 오류 처리
        console.log(e);
      } finally {
        this.pending = false;
      }
    }

    private drawChart({ data, dates, topLabel, topCountLabel }: ChartData) {
      this.chartOn = true;
      this.downloadBtnShow = true;
      const canvas = this.$refs.chart;
      const ctx = canvas.getContext('2d');
      this.topScoreLabel = '';
      // TODO: 오류 처리
      if (!ctx) return;
      Chart.unregister(...registerables);
      Chart.register(...registerables);
      this.chart?.destroy();
      const entries = Object.entries(data);
      const datasets = entries.map(([key, value]) => {
        // TODO: 차트 색은 랜덤으로 생성 -> 고정이 필요하면 따로 코딩
        const color = randomColor();
        this.colorArray.push(color);
        return {
          label: key,
          data: value.map(v => v.buy),
          fill: false,
          // borderColor: this.getRandomColor(),
          tension: 0.1,
          borderColor: color,
          backgroundColor: color,
        }
      });

      this.topScoreLabel = topLabel.join(' ');
      this.topCountLabel = topCountLabel.join(' ');

      this.chart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: dates,
          datasets,
        },
        options: {
          scales: {
            x: {
              title: {
                display: true,
                text: '구매 일시',
              },
              beginAtZero: false,
              ticks: {
                autoSkip: true,
                autoSkipPadding: 20,  // TODO: X축을 생략하고 싶으면 이 값을 조절하면 됨
              },
            },
            y: {
              title: {
                display: true,
                text: '결제 금액(%)',
              },
              beginAtZero: false,
              ticks: {
                autoSkip: true,
                autoSkipPadding: 20,  // TODO: Y축을 생략하고 싶으면 이 값을 조절하면 됨
              },
            }
          },
          plugins: {
            legend: {
              position: 'top',
              align: 'start',
              labels: {
                filter: (item, chart) => {
                  // console.log(item, chart);
                  return true;
                },
                padding: 20,
              },
            },
            // title: {
            //   display: true,
            //   position: 'bottom',
            //   text: Object.keys(data).join('/'),
            //   font: {
            //     size: 20,
            //   },
            // }
          }
        }
      });
    }

    private update ({ startDate, endDate, selectors, firstOptions, secondOptions }: { startDate?: string; endDate?: string; selectors?: string[]; firstOptions?: number[]; secondOptions?: number[] }) {
      if (startDate) this.startDate = startDate;
      if (endDate) this.endDate = endDate;
      if (selectors) this.selectors = selectors;
      if (firstOptions) this.firstOptions = firstOptions;
      if (secondOptions) {
        this.secondOptions = secondOptions;
      }
      if(this.selectors.length < 2){
        this.secondOptions = [];
      }
      this.downloadBtnShow = false;
    }

    private drawCountChart({ data, dates }: ChartData) {
      const canvas = this.$refs.countChart;
      const ctx = canvas.getContext('2d');
      // TODO: 오류 처리
      if (!ctx) return;
      Chart.unregister(...registerables);
      Chart.register(...registerables);
      this.countChart?.destroy();
      const entries = Object.entries(data);
      const datasets = entries.map(([key, value], idx) => {
        // TODO: 차트 색은 랜덤으로 생성 -> 고정이 필요하면 따로 코딩
        const color = this.colorArray[idx]
        return {
          label: key,
          data: value.map(v => v.count),
          fill: false,
          // borderColor: this.getRandomColor(),
          tension: 0.1,
          borderColor: color,
          backgroundColor: color,
        }
      });

      this.countChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: dates,
          datasets,
        },
        options: {
          scales: {
            x: {
              title: {
                display: true,
                text: '구매 일시',
              },
              beginAtZero: false,
              ticks: {
                autoSkip: true,
                autoSkipPadding: 20,  // TODO: X축을 생략하고 싶으면 이 값을 조절하면 됨
              },
            },
            y: {
              title: {
                display: true,
                text: '구매자 수(%)',
              },
              beginAtZero: false,
              ticks: {
                autoSkip: true,
                autoSkipPadding: 20,  // TODO: Y축을 생략하고 싶으면 이 값을 조절하면 됨
              },
            }
          },
          plugins: {
            legend: {
              position: 'top',
              align: 'start',
              labels: {
                filter: (item, chart) => {
                  // console.log(item, chart);
                  return true;
                },
                padding: 20,
              },
            },
            // title: {
            //   display: true,
            //   position: 'bottom',
            //   text: Object.keys(data).join('/'),
            //   font: {
            //     size: 20,
            //   },
            // }
          }
        }
      });
      this.colorArray = [];
    }

    /*private async csvDownload () {
      // MARK: 데이터 검증
      if (!this.validateParams) {
        this.$toast.error('분석 변수를 선택하세요.');
        return;
      }
      const startDate = this.startDate;
      const endDate = this.endDate;
      const selectors = this.selectors;
      const firstOptions = this.firstOptions;
      const secondOptions = this.secondOptions;
      this.pending = true;
      try {
        //location.href=`/api/chart/data-list-new?startDate=${startDate}&endDate=${endDate}&selectors=${selectors}&firstOptions=${firstOptions}&secondOptions=${secondOptions}`;

        const sendData = {
          startDate,
          endDate,
          selectors,
          firstOptions,
          secondOptions,
        }
        await this.axios({
          url: '/chart/data-list',
          method: 'GET',
          params: sendData,
          responseType: 'blob',
        }).then((response)=>{
          console.log(response);
          const href = URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', 'file.csv')
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        })
      } catch (e) {
        console.log(e);
      } finally {
        this.pending = false;
      }
    }
*/

    private reset(){
      this.$refs.date.changeDate();
      this.selectors = [];
      this.chartOn = false;
      this.$router.push({
        name: 'dashboard'
      });
    }

    async xlsxDownload(){
      if (!this.validateParams) {
        this.$toast.error('분석 변수를 선택하세요.');
        return;
      }
      const startDate = this.startDate;
      const endDate = this.endDate;
      const selectors = this.selectors;
      const firstOptions = this.firstOptions;
      const secondOptions = this.secondOptions;
      this.pending = true;
      try{
        const sendData = {
          startDate,
          endDate,
          selectors,
          firstOptions,
          secondOptions,
        }
        const data = await this.axios({
          url: '/chart/xlsx-down',
          method: 'GET',
          params: sendData,
          responseType: 'blob',
        }).then((response)=>{
          const href = URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', 'file.xlsx')
          document.body.appendChild(link);
          link.click();
          /*document.body.removeChild(link);*/
          URL.revokeObjectURL(href);
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.pending = false;
      }
    }

    async pdfDownload(){
      if (!this.validateParams) {
        this.$toast.error('분석 변수를 선택하세요.');
        return;
      }
      this.pending = true;
      try{
        const doc = pdfInit();
        const marginInfo = {
          marginTop: 10,
        };
        isAddPageCheck_Before(marginInfo, doc);

        const titleImage = document.getElementById('view-chart');
        if(titleImage){
          const { dataUrl } = await this.getDataInfo(titleImage);
          addImage(dataUrl, PDF_IMAGE_TYPE.CONTENT, marginInfo, doc);
        }

        doc.save(`chartFile.pdf`);
      } catch (e) {
        console.log(e);
      } finally{
        this.pending = false;
      }

    }

    async getDataInfo(chartElement: HTMLElement): Promise<{ height: number; dataUrl: string }> {
      return await new Promise((resolve) => {
        html2canvas(chartElement).then((canvas) => {
          const height = canvas.height;
          const dataUrl = canvas.toDataURL('image/jpeg');

          return resolve({
            height: height,
            dataUrl: dataUrl,
          });
        });
      });
    }

  }
