import jsPDF from 'jspdf';
import { RESULT } from '@/types/result';
import PDF_IMAGE_TYPE = RESULT.PDF_IMAGE_TYPE;

const MARGIN_TOP_DEFAULT = 10;
const PAGE_TITLE_NEW = 200;
const PAGE_HEIGHT = 300;

const marginLeft = 15; // 25
const marginBottom = 10;
const width = 180;

const titleHeight = 20;
const contentHeight = 200;

export function pdfInit(): jsPDF {
    return new jsPDF('p', 'mm', 'a4');
}
// PDF COVER
export function firstPageAdd(image: any, doc: jsPDF) {
    doc.addImage(image, 'PNG', 0, 0, 210, 297);
    doc.addPage();
}

export function isAddPageCheck_Before(marginInfo: { marginTop: number }, doc: jsPDF) {
    if (marginInfo.marginTop > PAGE_TITLE_NEW) {
        doc.addPage();
        marginInfo.marginTop = MARGIN_TOP_DEFAULT;
    }
}

export function addImage(dataUrl: string, imageType: PDF_IMAGE_TYPE, marginInfo: { marginTop: number }, doc: jsPDF) {
    const isTitle = imageType === PDF_IMAGE_TYPE.TITLE;
    const height = isTitle ? titleHeight : contentHeight;
    const totalPage = titleHeight + contentHeight + marginBottom + MARGIN_TOP_DEFAULT + marginInfo.marginTop;

    if (totalPage >= 350) {
        doc.addPage();
        marginInfo.marginTop = MARGIN_TOP_DEFAULT;
    }

    doc.addImage(dataUrl, 'JPEG', marginLeft, marginInfo.marginTop, width, height);
    marginInfo.marginTop += height;
}
